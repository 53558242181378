
import { Component, Vue, Watch } from "vue-property-decorator";
import formatDate from "../../plugins/formatDate";
import StartGroup from "../../models/sportevent/StartGroup";
import StartGroupService from "../../services/sportevent/StartGroupService";
import PrintSettingsService from "../../services/helper/PrintSettingsService";

@Component({
  filters: {
    formatDate,
  },
})
export default class ListStartGroupComponent extends Vue {
  // Loading
  public loading = true;
  // Table
  public startGroups: StartGroup[] = [];

  async getAll(): Promise<void> {
    this.startGroups = await StartGroupService.getAllBySportevent(true);
    PrintSettingsService.setHeader();
    this.loading = false;
  }

  @Watch("$route", { immediate: true, deep: true })
  onUrlChange(): void {
    this.getAll();
  }
}
